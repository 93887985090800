
import { Component, Prop, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
// import OrderLogistics from "@/components/order/order-logistics.vue";
import PopoverSelect from "@/components/popover-select.vue";
import PopoverInput from "@/components/popover-input.vue";

@Component({
    components: {
        LsDialog,
        LsPagination,
        PopoverSelect,
        PopoverInput,
    },
})
export default class OrderPane extends Vue {
    @Prop() value: any;
    @Prop() pager!: any;

    selectIds: any = [];

    goodsIds: any = [];
    visible = false;

    remarks = ""; // 商家备注

    statusTab = {1:'未核销',2:'已核销',3:'已退回'}

    // 获取订单信息
    getOrderLists() {
        (this.$parent as any).getOrderLists();
    }

    handleOpen() {
        this.visible = true;
    }

    // 选择某条数据
    selectionChange(val: any[]) {
        this.selectIds = val.map((item) => item.id);
    }

    // 全选
    selectAll() {
        (this.$refs.paneTable as any).toggleAllSelection();
    }

    // 去订单详情
    toOrder(id: any) {
        this.$router.push({
            path: "/shop/order_list_edit",
            query: { id },
        });
    }

    setCostPrice(event: any, id: Number) {
        (this.$parent as any).handleCostPrice({
            id: id,
            tatal_cost_price: event,
        });
    }

    confirmOrder(id:any,val:Number) {
        (this.$parent as any).handleConfirmOrder({
            id: id,
            is_purchase: val,
        });
    }
}
